<template>
    <div class="clientcabin clientcabin-reasons">
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            Results & Reasons
                        </p>
                        <p>
                            See why {{ company }} can grow your business and the
                            results you can get with our team
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/megaphone-effect"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/reasons/boost-your-brand/1.png"
                                        height="220px"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            The
                                            {{ company }} Effect: That Gets Your
                                            Brand Heard By A Massive Audience
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        {{ company }} helps brands get more
                                        famous and trustworthy by sharing their
                                        content on big websites, podcasts, image
                                        directories, video platforms, and
                                        influential blogs that lots of people
                                        follow.
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/paid-advertising-vs-content-marketing"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/reasons/content-marketing/1.png"
                                        height="220px"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            Paid Advertising vs. Content
                                            Marketing Comparison: Are Facebook
                                            Ads Worth It?
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        Did you know that 61% of consumers are
                                        influenced by custom content? If that
                                        doesn’t pique your interest in content
                                        marketing then we don’t know what will!
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/techcrunch-wired-article-coverage-cost-to-submit-is-it-worth-it"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/reasons/cost-to-submit/1.png"
                                        height="220px"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            Forbes, TechCrunch & Wired Article
                                            Coverage | Cost to Submit & Is it
                                            Worth It?
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        Find out why {{ company }} skips
                                        submitting to TechCrunch & Wired, opting
                                        for strategies that boost business ROI
                                        and drive long-term growth.
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
        <div class="blueish">
            <v-container>
                <v-row justify="center">
                    <v-col cols="12" lg="9" xl="7" class="pt-10 pb-6">
                        <p class="text-h4 font-weight-medium">
                            <b>{{ company }}</b>
                            Customer Stories
                        </p>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-container>
            <v-row justify="center">
                <v-col cols="12" lg="9" xl="7" class="py-6 py-md-10">
                    <v-container class="pa-0">
                        <v-row>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/successful-wine-marketing-example"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/stories/successful-wine-marketing-example/1.png"
                                        max-height="220"
                                        position="top center"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            Successful Wine Marketing Example
                                            <br />
                                            Content Plan Strategy Grows Blog
                                            1100% in 21 Months
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        While every business is different, the
                                        results achieved by the winery store
                                        demonstrate the potential of content
                                        marketing and the power of our content
                                        amplification technique.
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/online-medical-store"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/stories/online-medical-store/1.png"
                                        max-height="220"
                                        position="top center"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            Online Medical Store Generates
                                            Millions in Sales From Just 113
                                            Pieces of Content in Less Than 12
                                            Months
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        This online medical store had only 561
                                        organic visitors (cumulative 2021) to
                                        their Shopify store for a lung cleansing
                                        device… which is kind of non-existent
                                        traffic.
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" lg="4">
                                <v-card
                                    class="item-card mx-auto"
                                    to="/reasons/car-dealership-marketing-strategy-example"
                                >
                                    <v-img
                                        src="@/assets/img/clientcabin/stories/car-dealership/1.png"
                                        max-height="220"
                                        position="top center"
                                    />

                                    <v-card-title>
                                        <div class="primary--text mt-2">
                                            Car Dealership Marketing Strategy
                                            Example: Keyword Ideas & Content
                                            Plan Boost Organic Traffic by 76.7%
                                        </div>
                                    </v-card-title>

                                    <v-card-subtitle
                                        class="mt-0 text-body-2 black--text"
                                    >
                                        Car Dealership Marketing Strategy
                                        Example: Keyword Ideas & Content Plan
                                        Boost Organic Traffic by 76.7% Share
                                        article link An automobile dealership
                                        attracted 76.7% more website
                                        <div
                                            class="primary--text mt-2 text-subtitle-1"
                                        >
                                            Read More
                                        </div>
                                    </v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { InjectReactive } from '@/utils/decorators';

import type { ClientCabin } from '@/types/ClientCabin';

@Component
export default class Reasons extends Vue {
    @InjectReactive({
        from: 'options',
        default() {
            return null;
        }
    })
    options!: Partial<ClientCabin> | null;

    get company() {
        return this.options?.business_name || 'Our Company';
    }
}
</script>

<style lang="scss" scoped>
.clientcabin-reasons::v-deep {
    background-color: $white;
    color: $black;

    .item-card {
        display: flex;
        flex-direction: column;
        height: 100%;

        .v-card__title {
            word-break: keep-all;
        }

        .v-card__subtitle {
            flex-grow: 100;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
}
</style>
